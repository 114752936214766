import React from 'react'
import Login from '../components/Login/Login';
import { useNavigate } from 'react-router-dom';

const LoginPage=()=> {
 
  const navigate = useNavigate();
  const onSuccess = () => {
    // Redirect to the home screen after successful login
    console.log("replace with actual logic");
    navigate('/home')
};

const onForgotPassword = () => {
    // Redirect to the forgot password screen
    console.log("replace with actual logic");

};

const onClickToRegister = () => {
    // Redirect to the registration screen
    navigate('/Register');

};

const onClickToHome = () => {
  navigate('/home');

};
 

  return (
    <div className="App">
   


     <Login
                onSuccess={onSuccess}
                onForgotPassword={onForgotPassword}
                onClickToRegister={onClickToRegister}
                onClickToHome={onClickToHome}
            />
    </div>
  );
}

export default LoginPage;