import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

async function PostRequest(path = '', data, isAuthenticated) {
  const storeId=1;
  let headers = {
    'Content-Type': 'application/json'
  };

  if (isAuthenticated) {
    const token = localStorage.getItem('token');
    headers['Authorization'] = `Bearer ${token}`;
  }

  try {
    const response = await axios.post(
      'https://beta.api.boss.m2r2.co.uk/api/' + path+'?storeId='+storeId,
      data,
      { headers } // Pass headers as an object with 'headers' key
    );

    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else if (response.status === 403) {
      toast.error('Username or Password is incorrect.');
      return null;
    } else if (response.status === 401) {
      toast.error('Authentication failed. Please try to login first.');
      return null;
    } else {
      toast.error('Server returned an error. Please try again later.');
      return null;
    }
  } catch (error) {
    console.error('Error in postData:', error);
    toast.error('An error occurred. Please try again later.');
    return null;
  }
}

export default PostRequest;
