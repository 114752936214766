import failureIcon from '../assests/failure.png';
import { useNavigate  } from 'react-router-dom';
import { Button } from '@mui/material';
const PaymentFailurePage = () => {
    const navigate = useNavigate();
  
    return (
      <div className="outlet-container">
        <div className="background-white">
          <div className="background-white-sub-container"> 
          <div className='centralised-text'>
              <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
              <div className='centralised-text'>
              <img
                  loading="lazy"
                  alt="icon"
                  srcSet={failureIcon}
                  className="shrink-0 rounded-lg aspect-[1.02] w-[45px]"
                  style={{ height: '80px', width: '80px' }}
                />
              </div>
              <br/><br/>
              <div className='centralised-text extra-large-text'>Payment failed, please retry payment</div>
              <br/><br/>If amount has been deducted from you, don't worry will be credited back to your account.
              <br/><br/><div className='centralised-text'><Button onClick={() => navigate("/checkout")} variant="soft">Continue</Button>
          </div></div>
      </div></div></div>
    )
  }
  
  export default PaymentFailurePage
  