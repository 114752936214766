import React, { createContext, useContext, useState, useCallback } from 'react';
import { URL } from '../constants/api';
// import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);
    const [cartItemCount, setCartItemCount] = useState(0);
    // const navigate = useNavigate();

    const fetchCartData = useCallback(async () => {
        try {
            const response = await fetch(`${URL}/api/public/v1/user/cart/items`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch cart data: ${response.statusText}`);
            }
            const data = await response.json();
            setCartItems(data);
            const itemCount = data.reduce((total, item) => total + item.quantity, 0);
            setCartItemCount(itemCount);
        } catch (error) {
            console.error('Error fetching cart data:', error);
        }
    }, []);

    const addItemToCart = async (itemId, quantity) => {
        try {
            const jwtToken = localStorage.getItem('token');
            // if (!jwtToken) {
            //     toast.warn('You need to sign in to add items to the cart.', {
            //         onClose: () => navigate("/login"),
            //     });
            //     throw new Error("JWT token not found in localStorage");
            // }

            const response = await fetch(`${URL}/api/public/v1/user/cart/addItem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify({ itemId, quantity }),
            });

            if (!response.ok) {
                throw new Error('Failed to add item to cart');
            }
            console.log('Item added to cart successfully');
            await fetchCartData();
        } catch (error) {
            console.error('Error adding item to cart:', error);
        }
    };

    const removeItemFromCart = async (itemId, quantity) => {
        try {
            const response = await fetch(`${URL}/api/public/v1/user/cart/removeItem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ itemId, quantity }),
            });

            if (!response.ok) throw new Error('Failed to remove item from cart');
            console.log('Item removed from cart successfully');
            await fetchCartData();
        } catch (error) {
            console.error('Error removing item from cart:', error);
        }
    };

    const handleQuantityChange = async (itemId, change) => {
        try {
            const item = cartItems.find(cartItem => cartItem.item.itemId === itemId);
            if (!item) throw new Error('Item not found in cart');

            const newQuantity = item.quantity + change;
            if (newQuantity <= 0) {
                await removeItemFromCart(item.item.itemId, item.quantity);
                setCartItems(prevCart => prevCart.filter(cartItem => cartItem.item.itemId !== item.item.itemId));
            } else {
                await addItemToCart(item.item.itemId, change);
                setCartItems(prevCart =>
                    prevCart.map(cartItem =>
                        cartItem.item.itemId === item.item.itemId ? { ...cartItem, quantity: newQuantity } : cartItem
                    )
                );
            }
        } catch (error) {
            console.error('Error updating quantity:', error);
        }
    };

    return (
        <CartContext.Provider value={{ cartItems, cartItemCount, addItemToCart, removeItemFromCart, handleQuantityChange, fetchCartData }}>
            {children}
        </CartContext.Provider>
    );
};
