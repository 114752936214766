import React from 'react'
import MenuAppBar from '../components/Header';
import './styles.css'
import burgerImage from '../assests/burger.svg';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';


const RatingForProduct = () => {
  

  return (
    <>
      <MenuAppBar />
      <Typography variant="h6" sx={{ textAlign: 'left', margin: 5 }}>How much do you like this product? </Typography>
      <Card sx={{ 
  display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'center', 
  marginTop: 2, 
  marginLeft: { xs: 10, sm: 3, md: 4, lg: 4 }, 
  marginRight: { xs: 10, sm: 3, md: 4, lg: 4 }, 
  maxWidth: 500,
  marginBottom:5
}}>
  <CardMedia
    component="img"
    sx={{ 
      width: {xs:40,sm:30,md:50,lg:80},
      height: {xs:30,sm:30,md:50, lg:80}, 
      marginRight: 2,
      marginTop: 2 
    }}
    image={burgerImage}
    alt="Food items"
  />
  <CardContent sx={{ 
    flex: '1', 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'flex-start' 
  }}>
    <Typography variant="body1" sx={{ marginLeft: 2 }}>Veg Burger</Typography>
    <Rating sx={{ marginLeft:1.5}}></Rating>
  </CardContent>
</Card>

<Link to="/orders" style={{textDecoration:'none',color:'#000000'}}>
  Back to orders
</Link>



      

    </>
  )
}

export default RatingForProduct;
