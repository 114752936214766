import React, { useState } from 'react';
import { FaEyeSlash, FaEye } from 'react-icons/fa';
import './style.css';


const Input = ({ type, value, onChange, placeholder, prefixIcon, suffixIcon }) => {
    const [showPassword, setShowPassword] = useState(false);
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  
    return (
      <div className="input-wrapper">
        {prefixIcon && <span className="icon">{prefixIcon}</span>}
        <input
          type={type === 'password' && !showPassword ? 'password' : 'text'}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          style={{ backgroundColor: 'var(--card-color)' }} // Use var(--card-color) to refer to the CSS variable

        />
        {suffixIcon && type === 'password' && (
          <span className="icon" onClick={togglePasswordVisibility}>
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        )}
        {suffixIcon && type !== 'password' && <span className="icon">{suffixIcon}</span>}
      </div>
    );
  };
  
  export default Input;
