import React, { useState, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
// import { URL } from '../constants/api';
import { useCart } from '../context/CartContext';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';



const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

export default function MenuAppBar() {
    const [anchorEl, setAnchorEl] = useState(null);
    // const [cartItemCount, setCartItemCount] = useState(0);
    const userId = localStorage.getItem('userid');
    // const [cartItem, setCartItem] = useState([]);
    const navigate = useNavigate();
    const { cartItemCount, fetchCartData } = useCart();


    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleViewAccountDetails = () => {
        // Close the menu and navigate to the account details page
        handleMenuClose();
        navigate('/account-details');
    };

    const handleViewOrders = () => {
        // Close the menu and navigate to the orders page
        handleMenuClose();
        navigate('/orders');
    };





    useEffect(() => {
        fetchCartData();
        const intervalId = setInterval(fetchCartData, 60000);

        return () => clearInterval(intervalId);
    }, [fetchCartData]);

    const handleLogout = () => {
        localStorage.clear();
        navigate("/")

    };


    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" style={{ backgroundColor: '#ffffff' }}>
                    <Toolbar>
                        <Link to="/home" style={{ textDecoration: 'none' }}>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ flexGrow: 1, textAlign: 'left', color: '#0094DC' }}
                                noWrap
                            >
                               BOSS
                            </Typography>
                        </Link>
                        <Grid container spacing={2} style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px' }}>


                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <div style={{ position: 'relative', borderRadius: '30px', padding: '5px', border: '1px solid #0094DC', width: 70 }}>
                                    <InputBase
                                        placeholder="Search"
                                        inputProps={{ 'aria-label': 'search' }}
                                        sx={{ ml: 1, left: 15 }}
                                    />
                                    <IconButton
                                        type="submit"
                                        sx={{ p: '10px', position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', color: '#0094DC' }}
                                        aria-label="search"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </div>
                            </Grid>


                        </Grid>






                        {/* <Button color="inherit">Login</Button> */}
                        {userId && (
                            <>
                                <Avatar sx={{ width: 32, height: 32, bgcolor: '#0094DC', marginLeft: 2 }} onClick={handleAvatarClick}>
                                    <PersonIcon />
                                </Avatar>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={handleViewAccountDetails}>View Account Details</MenuItem>
                                    <MenuItem onClick={handleViewOrders}>Your Orders</MenuItem>
                                </Menu>
                            </>
                        )}
                        <IconButton sx={{ color: '#0094DC' }}>

                            <Link to="/cart">
                                <StyledBadge badgeContent={cartItemCount} color="primary">
                                    <ShoppingCartIcon style={{ color: '#0094DC' }} />

                                </StyledBadge>
                            </Link>

                        </IconButton>

                        <IconButton sx={{ color: '#0094DC' }}>
                            <NotificationsIcon />
                        </IconButton>
                        {/* <IconButton sx={{ color: '#0094DC' }}>
                            <LogoutIcon />
                        </IconButton> */}
                        {userId && (
                            <IconButton sx={{ color: '#0094DC' }} onClick={handleLogout}>
                                <LogoutIcon />
                            </IconButton>
                        )}
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 1, color: '#0094DC' }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Box >

        </>


    );
}