import React, { useState } from 'react'
import MenuAppBar from '../components/Header';
import { Typography, Select, MenuItem, Dialog, DialogTitle, DialogContent, TextField, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { Button } from '@mui/material';
import burgerImage from '../assests/burger.svg';


const ReturnOrReplace = () => {
  const [selection, setSelection] = useState('');
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');

  const handleChange = (event) => {
    setSelection(event.target.value);
    setOpen(true); // Open the dialog box when a selection is made
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };
  return (
    <>
      <MenuAppBar />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card sx={{ display: 'flex', alignItems: 'center', mt: 4, width: {lg:'40%'}, height: '30%', ml: 6, border: 'none' }}>
          <CardMedia
            component="img"
            sx={{ width: 80, lg:{ml:10} }} 
            image={burgerImage}
            alt="Food Image"
          />
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography variant="h5" component="div" sx={{lg:{mr:4}}}>
              Veg Burger
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Typography sx={{ mt: 2,ml:5 }}>What do you want to do with this item?</Typography>
      <Select
        value={selection}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{mt:2,ml:5}}
      >
        <MenuItem value="" disabled>
          Choose an option
        </MenuItem>
        <MenuItem value="return">Return</MenuItem>
        <MenuItem value="replace">Replace</MenuItem>
      </Select>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{`Reason for ${selection}`}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            label="Reason"
            type="text"
            fullWidth
            variant="standard"
            value={reason}
            onChange={handleReasonChange}
          />
          <Button onClick={handleClose}>Submit</Button>
        </DialogContent>
      </Dialog>


    </>
  )
}

export default ReturnOrReplace

