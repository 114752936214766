import MenuAppBar from "../components/Header";
import PostRequest from '../services/PostRequest';
import React, { useState, useRef } from 'react';
import { useNavigate,useLocation } from "react-router-dom";

const VerifyEmailPage = () => {
    const location = useLocation();
    const email = location.state?.email;
      const navigate = useNavigate();
      const [otp, setOtp] = useState(new Array(6).fill(""));
      const inputRefs = useRef([]);
      const [showOTPError, setShowOTPError] = useState(false);
  
      const handleChange = (element, index) => {
        const value = element.value;
        if (/^[0-9]$/.test(value) || value === "") {
          const newOtp = [...otp];
          newOtp[index] = value;
          setOtp(newOtp);
    
          if (value !== "" && index < 5) {
            inputRefs.current[index + 1].focus();
          }
    
          if (index === 5 && newOtp.join('').length === 6) {
            verifyOtp(newOtp.join(''));
          }
        }
      };
    
      const handleKeyDown = (event, index) => {
        if (event.key === "Backspace" && index > 0 && otp[index] === "") {
          inputRefs.current[index - 1].focus();
        }
        setShowOTPError(false);
      };
    
      const verifyOtp = async (otp) => {
        const data = {
          otp: otp,
          email: email,
        };
       await PostRequest('auth/verify/email', data, false)
              .then(response => {
                  console.log("success")
                  if(response){
                    navigate("/login");
                  } else{
                    setShowOTPError(false);
                    
                  }
              });
      };
  
    return (
      <div>
        <div><MenuAppBar/>
      <br/>    <br/>
      <br/>
      <br/><br/>    <br/>
      <br/>
      <br/>
      <div className='login-form'>
  
      <div className="registration-further-details">
                    <div className="login-content-wrapper">
                      <h3>Please Verfify your Email</h3>
                      <br/>
                      <div className="full-name-conatiner">
            
                            
                              <div className="input-padding"></div>
                              OTP has been sent to your registered email. Please open you your "email" and enter the OTP below. Remember OTP will be valid for 15 minutes only.
                            
                              <div className="input-padding"></div>
                              
                              
                              <div className="input-padding"></div>
                              
                          
                              </div>
                              <div className="input-padding"></div>
                              
                              <div className="input-padding"></div>
                              <div>
                                {otp.map((digit, index) => (
                                  <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={digit}
                                    onChange={e => handleChange(e.target, index)}
                                    onKeyDown={e => handleKeyDown(e, index)}
                                    ref={el => inputRefs.current[index] = el}
                                    style={{ width: '40px', marginRight: '8px', textAlign: 'center', fontSize: '20px',border:'solid 1px #0094DC' }}
                                  />
                                ))}
                              </div>
                              <br />
                              <button  className='login-form-btn'>Resend Email</button>
                              <div className="input-padding"></div>
                              <br />
                              {showOTPError && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            OTP Expired or failed
          </div>
        )}
                              
                          </div></div>
      </div></div>
      </div>
    )
  }
  
  export default VerifyEmailPage
  
  