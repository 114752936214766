import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import EmptyCartImage from '../assests/cart1.svg';
import MenuAppBar from '../components/Header';
import { Button, TextField } from '@mui/material';
import { useCart } from '../context/CartContext';
// import { URL } from '../constants/api';
import { styled } from '@mui/system';
import Divider from '@mui/material/Divider';

// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import IconButton from '@mui/material/IconButton';

const CartComponent = () => {
    // const { cartItems, updateCartItemQuantity } = useCart();
    const [totalPrice, setTotalPrice] = useState(0);
    // const [cartItem, setCartItem] = useState([]);
    const { cartItems, handleQuantityChange, fetchCartData } = useCart();

    const StyledDiv = styled('div')(({ theme }) => ({
        padding: '5px 10px',
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    }));

    useEffect(() => {

        let totalPrice = 0;
        cartItems.forEach(item => {
            totalPrice += item.item.sellingPrice * item.quantity;
        });
        setTotalPrice(totalPrice.toFixed(2));
    }, [cartItems]);
    // const viewMyCart = async () => {
    //     try {
    //         const jwtToken = localStorage.getItem('token');
    //         if (!jwtToken) {

    //             throw new Error("JWT token not found in localStorage");

    //         }


    //         const response = await fetch("https://beta.api.boss.m2r2.co.uk/api/public/v1/user/cart/items", {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${jwtToken}`,
    //             },
    //         });

    //         if (!response.ok) {
    //             throw new Error(`Failed to view cart: ${response.statusText}`);
    //         }

    //         const data = await response.json();
    //         console.log(data);
    //         setCartItem(data);
    //         console.log(data)
    //     } catch (error) {
    //         console.error('Error viewing cart:', error);
    //     }
    // };
    useEffect(() => {
        fetchCartData();
    }, [fetchCartData]);

    // const handleQuantityChanges = (index, value) => {
    //     const updatedCartItems = [...cartItems];
    //     updatedCartItems[index].quantity = Math.max(updatedCartItems[index].quantity + value, 0);
    //     updateCartItemQuantity(updatedCartItems[index].id, updatedCartItems[index].quantity);
    //     if (value > 0) {
    //         addItemToCart(updatedCartItems[index].id);
    //     } else if (value < 0) {
    //         removeItemFromCart(updatedCartItems[index].id);
    //     }
    // };



    // const handleAddToCart = (itemId, quantity) => {
    //     addItemToCart(itemId, quantity);
    // }
    // const addItemToCart = async (itemId, quantity) => {
    //     try {
    //         const response = await fetch(`${URL}/api/public/v1/user/cart/addItem`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('token')}`,
    //             },
    //             body: JSON.stringify({ itemId, quantity }),
    //         });
    //         if (!response.ok) {
    //             throw new Error('Failed to add item to cart');
    //         }
    //         console.log('Item added to cart successfully');
    //         await viewMyCart();
    //     } catch (error) {
    //         console.error('Error adding item to cart:', error);
    //     }
    // };
    // const removeItemFromCart = async (itemId, quantity) => {
    //     try {
    //         const response = await fetch(`${URL}/api/public/v1/user/cart/removeItem`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('token')}`,
    //             },
    //             body: JSON.stringify({ itemId, quantity }),
    //         });
    //         if (!response.ok) throw new Error('Failed to remove item from cart');
    //         console.log('Item removed from cart successfully');
    //         await viewMyCart();
    //     } catch (error) {
    //         console.error('Error removing item from cart:', error);
    //     }
    // };
    // // const handleQuantityChange = async (index, change, item) => {
    //     try {
    //         const newQuantity = item.quantity + change;
    //         if (newQuantity <= 0) {
    //             await removeItemFromCart(item.item.itemId, item.quantity);
    //             setCartItem(prevCart => prevCart.filter((_, i) => i !== index));
    //         } else {
    //             await addItemToCart(item.item.itemId, change);
    //             setCartItem(prevCart =>
    //                 prevCart.map((cartItem, i) => i === index ? { ...cartItem, quantity: newQuantity } : cartItem)
    //             );
    //         }
    //     } catch (error) {
    //         console.error('Error updating quantity:', error);
    //     }
    // };



    return (
        <>
            <MenuAppBar />
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <Typography variant="h4">My Cart</Typography>
                {cartItems.length === 0 ? (
                    <div style={{ marginTop: '20px' }}>
                        <img src={EmptyCartImage} alt="Your Cart" style={{ width: '200px', marginTop: '20px' }} />
                        <Typography variant="body1">Your cart is empty</Typography>
                        <div style={{ marginTop: '20px' }}>
                            <Link to="/home" style={{ textDecoration: 'none' }}>Go to Home</Link>
                        </div>
                    </div>
                ) : (
                    <>
                        <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {cartItems.map((item, index) => (
                                <div key={index} style={{ position: 'relative', borderRadius: '30px', boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.1)', padding: '10px', display: 'flex', alignItems: 'center', marginTop: '20px', maxWidth: '325px' }}>
                                    <div>
                                        <Typography variant="body1">{item.item.name}</Typography>
                                        <Typography variant="body1">Price: £ {item.item.sellingPrice}</Typography>
                                        <div style={{ display: 'flex', alignItems: 'center', borderRadius: '8px', border: '1px solid #ccc', padding: '5px', marginTop: '10px' }}>
                                            <StyledDiv onClick={() => handleQuantityChange(item.item.itemId, -1)}>-</StyledDiv>
                                            <Divider orientation="vertical" flexItem />
                                            <TextField
                                                type="number"
                                                variant="outlined"
                                                value={isNaN(item.quantity) ? '' : item.quantity}
                                                onChange={(e) => handleQuantityChange(item.item.itemId, parseInt(e.target.value) - item.quantity)}
                                                inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                                style={{ margin: '0 5px', width: '80px' }}
                                            />
                                            <Divider orientation="vertical" flexItem />
                                            <StyledDiv onClick={() => handleQuantityChange(item.item.itemId, 1)}>+</StyledDiv>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                            <div style={{ borderRadius: '10px', boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
                                <Typography variant="body1">Total: £ {totalPrice}</Typography>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                            <Link to="/checkout" style={{ textDecoration: 'none' }}>
                                <Button variant="outlined" style={{
                                    borderRadius: '8px', color: "#0094DC", '&:hover': {
                                        backgroundColor: '#0094DC',
                                        color: '#fff',
                                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                    },
                                    '&:active': {
                                        backgroundColor: '#0094DC',
                                        color: '#fff',
                                        boxShadow: 'none',
                                    },
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', textTransform: 'none', borderColor: 'black'
                                }}>
                                    Proceed to checkout
                                </Button>
                            </Link>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default CartComponent;
