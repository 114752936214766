import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import MenuAppBar from '../components/Header';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { URL } from '../constants/api';

const OrderDetailsPage = () => {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [orderDetail, setOrderDetail] = useState(null);  
    const [loading, setLoading] = useState(true);          

    useEffect(() => {
        const userId = localStorage.getItem('userid');
        if (!userId) {
            toast.warn('You need to sign in to view your order details.', {
                onClose: () => navigate('/login'),
            });
            return;
        }

        const fetchData = async () => {
            try {
                const response = await fetch(`${URL}/api/public/v1/user/view/order/${orderId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setOrderDetail(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [orderId, navigate]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!orderDetail) {
        return <div>No order details available</div>;
    }

    return (
        <>
            <ToastContainer />
            <MenuAppBar />
            <Container maxWidth="sm">
                <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
                    <Paper elevation={3} sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', my: 5, borderRadius: 2, borderColor: 'primary.main', boxShadow: 3 }}>
                        <Typography variant="h5" gutterBottom>Order Summary</Typography>
                       
                            <Typography variant="subtitle1">Order Number: {orderDetail.orderNumber}</Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Shipping Address" secondary="London, UK" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Item(s) Subtotal" secondary={`£${orderDetail.unitPrice}`} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Order Type" secondary={orderDetail.orderType} />
                                </ListItem>
                            </List>
                            <Button
                                variant="outlined"
                                sx={{
                                    marginTop: '20px',
                                    marginBottom: 4,
                                    borderColor: '#0094DC',
                                    ':hover': {
                                        bgcolor: '#0094DC',
                                        color: '#fff',
                                    },
                                    boxShadow: '0 3px 5px 2px rgba(0, 148, 220, .3)',
                                }}
                            >
                                Buy it again
                            </Button>
                    
                    </Paper>
                </Box>
            </Container>
        </>
    );
};

export default OrderDetailsPage;
