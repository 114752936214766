import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/system';
import burgerImage from '../assests/burger.svg';
import soupImage from '../assests/soup.svg';
import pastaImage from '../assests/pasta.svg';
// import juicesImage from '../assests/juices.svg';
import MenuAppBar from '../components/Header';
import HomeFooter from '../components/HomeFooter';
import SelectedCategory from './SelectedCategory';
import { URL } from '../constants/api';
import RestrauntImage from '../assests/Restraunt.svg';

// import { useNavigate } from 'react-router-dom';

const RoundedPaper = styled(Paper)({
    borderRadius: 10,
});




const popularPicks = [
    { name: 'Cheese Burger', image: burgerImage, price: 9.00 },
    { name: 'Veg pasta', image: pastaImage, price: 15.00 }
]

const recommendedChoice = [
    { name: 'Veg Pasta', image: pastaImage, price: 9.00 },
    { name: 'Soup', image: soupImage, price: 5.00 }
]
const newest = [
    { name: 'Veg Pasta', image: pastaImage, price: 9.00 },
    { name: 'Soup', image: soupImage, price: 5.00 }
]



const HomeComponent = () => {

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [displayHomeElement, setDisplayHomeElement] = useState(true);
    const [changeColor, setChangeColor] = useState(false);
    const [storeDetails, setStoreDetails] = useState([]);
    // const [selectedVariety, setSelectedVariety] = useState(null);
    // const [displayCategory, setDisplayCategory] = useState(true);
    // const navigate = useNavigate();
    const [categories1, setCategories1] = useState([]);

    const [categoryId, setCategoryId] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${URL}/api/public/v1/user/view/branches/1/categories`);
                // console.log(response);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const uniqueCategories = data.filter((category, index, self) =>
                    category && category.name && self.findIndex(c => c.name === category.name) === index
                );
                setCategories1(uniqueCategories);
                // console.log(categories1)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // empty dependency array ensures this effect runs only once




    const handleCategoryClick = (category) => {
        setDisplayHomeElement(false);
        setSelectedCategory(category);
        setCategoryId(category.categoryId);
        setChangeColor(!changeColor);
    };
    const lightenColor = (hex, opacity) => {
        hex = hex.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return `rgba(${r},${g},${b},${opacity})`;
    };

    useEffect(() => {
        const fetchStore = async () => {
            try {
                const response = await fetch(`${URL}/api/v1/public/store?url=https://chennaidosa.stores.m2r2.co.uk`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setStoreDetails(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchStore();
    }, []);

    // const cardStyle = {
    //     backgroundImage: { RestrauntImage },
    //     backgroundSize: 'cover',
    //     backgroundPosition: 'center',
    //     color: '#0094DC',
    //     textAlign: 'center',
    //     fontFamily: 'Helvetica Neue',
    //     marginTop: 3,
    // };
    const cardStyle = {
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${RestrauntImage})`, 
        backgroundRepeat: 'no-repeat', 
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        borderRadius: '10px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
        padding: '20px', 
        textAlign: 'center',
        width: '100%',
        paddingTop: '30.25vw', 
    };
    
    
      
      
      





    return (
        <>
            <MenuAppBar />

            <div style={{ marginTop: '20px' }}>
                <Card variant="outlined" sx={{ border: 'none', marginTop: 2 }}>
                    <CardContent style={cardStyle}>
                        <Typography variant="h4" sx={{ fontWeight: 'italic', marginBottom: '10px' }}>
                            {storeDetails.brandName}
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '16px' }}>
                            {storeDetails.brandDescription}
                        </Typography>
                    </CardContent>
                </Card>
            </div>

            <>
                <Typography variant="body2" sx={{ mt: 2, mb: 1, textAlign: 'left', paddingLeft: '10px', fontFamily: "Helvetica Neue" }}>
                    What do you like to eat?
                </Typography>

                <div style={{ overflowX: 'auto', overflowY: 'hidden', maxWidth: '100%', whiteSpace: 'nowrap', WebkitOverflowScrolling: 'touch', scrollbarWidth: 'none', 'msOverflowStyle': 'none' }}>
                    <Grid container spacing={5} style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px', display: 'inline-block' }}>
                        {categories1.map((category, index) => (
                            <Grid item xs={5} sm={2} md={2} lg={1} key={index} style={{ display: 'inline-block' }}>
                                <RoundedPaper elevation={2} style={{ width: '145px', height: '45px', border: 'none', display: 'inline-block' }}>
                                    <Card
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            border: 'none',
                                            backgroundColor: changeColor && selectedCategory === category ? lightenColor('#0094DC', 0.4) : 'inherit',

                                            transition: 'background-color 0.3s, box-shadow 0.3s',
                                            '&:hover': {
                                                backgroundColor: lightenColor('#0094DC', 0.4),
                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                            },
                                            '&:active': {
                                                backgroundColor: lightenColor('#0094DC', 0.4),
                                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                            },
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: '8px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleCategoryClick(category)}
                                        >
                                            {/* <img
                                    src={category.image}
                                    alt={category.name}
                                    style={{ width: '40px', height: '30px', objectFit: 'cover', marginRight: '8px' }}
                                /> */}
                                            <Typography variant="subtitle1" component="div">
                                                {category.name}
                                            </Typography>
                                        </div>
                                    </Card>
                                </RoundedPaper>
                            </Grid>
                        ))}
                    </Grid>
                </div>



            </>




            {selectedCategory && !displayHomeElement && (
                <div style={{ marginTop: '20px', overflowX: 'auto' }}>
                    <SelectedCategory categoryId={categoryId} selectedCategory={selectedCategory} />
                </div>
            )}

            {displayHomeElement && (
                <>

                    <Typography variant="body2" sx={{ mt: 2, mb: 1, textAlign: 'left', paddingLeft: '10px', fontFamily: "Helvetica Neue" }}>
                        Popular Picks
                    </Typography>
                    <Grid container spacing={2} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        {popularPicks.map((popularPick, index) => (
                            <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                                <RoundedPaper elevation={2} style={{ height: '100%' }}>
                                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <CardMedia
                                            component="img"
                                            sx={{
                                                height: { xs: '100px', sm: '100px', md: '250px', lg: '200px' },
                                                objectFit: 'cover'
                                            }}
                                            image={popularPick.image}
                                            alt={popularPick.name}
                                        />
                                        <CardContent sx={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            textAlign: 'center'
                                        }}>
                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                {popularPick.name}
                                            </Typography>
                                            <Typography variant="subtitle1" component="div">
                                                $ {popularPick.price.toFixed(2)}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </RoundedPaper>
                            </Grid>
                        ))}
                    </Grid>



                    <Typography variant="body2" sx={{ mt: 2, mb: 1, textAlign: 'left', paddingLeft: '10px', fontFamily: "Helvetica Neue" }}>
                        Newest
                    </Typography>
                    <Grid container spacing={2} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        {newest.map((newest, index) => (
                            <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                                <RoundedPaper elevation={2}>
                                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <CardMedia
                                            component="img"
                                            height={{ xs: '50px', sm: '100px', md: '250px', lg: '200px' }}
                                            image={newest.image}
                                            alt={newest.name}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                {newest.name}
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                $ {newest.price.toFixed(2)}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </RoundedPaper>
                            </Grid>

                        ))}
                    </Grid>
                    <Typography variant="body2" sx={{ mt: 2, mb: 1, textAlign: 'left', paddingLeft: '10px', fontFamily: "Helvetica Neue" }}>
                        Recommended Choice
                    </Typography>

                    <Grid container spacing={2} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        {recommendedChoice.map((recommendedChoice, index) => (
                            <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                                <RoundedPaper elevation={2}>
                                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                        <CardMedia
                                            component="img"
                                            height={{ xs: '50px', sm: '100px', md: '250px', lg: '200px' }}
                                            image={recommendedChoice.image}
                                            alt={recommendedChoice.name}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                {recommendedChoice.name}
                                            </Typography>
                                            <Typography gutterBottom variant="subtitle1" component="div">
                                                $ {recommendedChoice.price.toFixed(2)}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </RoundedPaper>
                            </Grid>

                        ))}
                    </Grid>

                </>
            )}



            <HomeFooter />
        </>


    );
};


export default HomeComponent;
