import React, { useEffect, useState } from 'react';
import MenuAppBar from '../components/Header';
import { useParams ,Link} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const InvoicePage = () => {
  const { invoiceId } = useParams();
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      if (!invoiceId) return;

      try {
        const response = await fetch(`https://beta.api.boss.m2r2.co.uk/api/v1/fetch/invoice?invoiceId=${invoiceId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setInvoiceDetails(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching invoice data:", error);
        setIsLoading(false);
      }
    };

    fetchInvoiceDetails();
  }, [invoiceId]);

  if (isLoading) {
    return (
      <Container style={styles.loadingContainer}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      <MenuAppBar />
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Invoice
        </Typography>
      </Toolbar>
      <Container maxWidth="md" style={styles.container}>
        <Card>
          <CardContent>
            {invoiceDetails ? (
              <>
                <Typography variant="h5" gutterBottom>
                  Invoice ID # {invoiceDetails.invoiceId}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Invoice Date: {invoiceDetails.invoiceCreatedDate}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Products:
                </Typography>
                <Grid container spacing={2}>
                  {Object.keys(invoiceDetails.products).map((productName, index) => (
                    <Grid item xs={12} key={index}>
                      <Typography variant="body1">
                        {productName}: {invoiceDetails.products[productName]}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                <Typography variant="body1" gutterBottom>
                  Total Paid: £{invoiceDetails.purchaseRate}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h6">Invoice Not Found</Typography>
                <Typography variant="body1">
                  There was an error fetching the invoice details. Please try again or contact support.
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
        <Button variant="outlined" style={styles.backButton}  component={Link}  sx={{
                                width: '120px',
                                marginTop: 2,
                                marginLeft: { xs: 15, sm: 40, md: 30, lg: 50 },

                                '&:hover': {
                                    backgroundColor: '#0094DC',
                                    color: '#fff',
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                },
                                '&:active': {
                                    backgroundColor: '#0094DC',
                                    color: '#fff',
                                    boxShadow: 'none',
                                },
                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
                            }}  >
          Go Back
        </Button>
      </Container>
    </>
  );
};

const styles = {
  container: {
    marginTop: '20px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  backButton: {
    marginTop: '20px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

export default InvoicePage;
