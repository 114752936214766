import React , { useState } from 'react'
import { Paper ,Button } from '@mui/material';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import MenuAppBar from '../components/Header';
import Grid from '@mui/material/Grid';

const ReviewProduct = () => {
    const [reviewTitle, setReviewTitle] = useState('');
    const [reviewText, setReviewText] = useState('');
    const [rating, setRating] = useState(2);
  
    const handleTitleChange = (event) => {
      setReviewTitle(event.target.value);
    };
  
    const handleReviewChange = (event) => {
      setReviewText(event.target.value);
    };
  
    const handleRatingChange = (event, newValue) => {
      setRating(newValue);
    };
    const handleSubmit = () => {
      
        console.log('Review Submitted', { reviewTitle, rating, reviewText });
     
      };
  return (
    <>
    <MenuAppBar/>
    <div>
      
      <Paper style={{ padding: '20px', margin: '20px' }}>
      <Grid container spacing={2} alignItems="center">
         <Grid item xs={12} sm={3}>
        <Typography component="legend" align='left' > Title</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
        <input
          type="text"
          value={reviewTitle}
          onChange={handleTitleChange}
          placeholder="Enter the title of your review"
          style={{ width: '70%', marginBottom: '10px' }}
        />
        </Grid>
        <Grid item xs={12} sm={3}>
        <Typography component="legend" align='left'>Your Rating</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
        <Rating
          name="simple-controlled"
          value={rating}
          onChange={handleRatingChange}
        />
        </Grid>
        <Grid item xs={12} sm={3}>
        <Typography component="legend"align='left'>Review</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
        <textarea
          value={reviewText}
          onChange={handleReviewChange}
          placeholder="Write your review here"
          rows={4}
          style={{ width: '80%' }}
        />
        </Grid>
        </Grid>
        <Box component="fieldset" mb={3} borderColor="transparent">
          <Button
          variant="outlined"
          onClick={handleSubmit}
          style={{ marginTop: '10px' }}
        >
          Submit Review
        </Button>
      </Box>
    </Paper>
    </div>
    </>
  )
}

export default ReviewProduct;
