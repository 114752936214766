import React, { useState, useEffect } from 'react';
// import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { URL } from '../constants/api';

const DeliveryOptions = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [deliveryOptions, setDeliveryOptions] = useState([]);

    useEffect(() => {
        const fetchDeliveryOptions = async () => {
            try {
                const response = await fetch(`${URL}/api/v1/fetch/delivery-options`);
                const data = await response.json();
                setDeliveryOptions(data.slice(0, 76).map((option, index) => ({
                    value: `option-${index}`,
                    label: option
                })));
            } catch (error) {
                console.error('Error fetching delivery options:', error);
            }
        };

        fetchDeliveryOptions();
    }, []);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <div>
            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography 
                    variant="h4" 
                    style={{ 
                        marginBottom: '20px', 
                        marginTop: '30px', 
                        fontSize: '16px', 
                        whiteSpace: 'nowrap', 
                        paddingLeft: '40px' 
                    }}
                >
                    Select Delivery options
                </Typography>
            </div> */}
            <div 
                style={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    marginTop: '-3px', 
                    gap: '5px', 
                    flexWrap: 'wrap' 
                }}
            >
                <FormControl variant="outlined" style={{ minWidth: 200 }}>
                    <InputLabel id="delivery-options-label">Delivery Options</InputLabel>
                    <Select
                        labelId="delivery-options-label"
                        id="delivery-options"
                        value={selectedOption}
                        onChange={handleOptionChange}
                        label="Delivery Options"
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 224, // You can adjust this height to your needs
                                },
                            },
                            disablePortal: true,
                        }}
                    >
                        {deliveryOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

export default DeliveryOptions;
