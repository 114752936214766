import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
// import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
// import FoodDetail from './FoodDetail';
import { useNavigate } from 'react-router-dom';
import { URL } from '../constants/api';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import { ToastContainer, toast } from 'react-toastify';

const SelectedCategory = ({ categoryId }) => {
    const navigate = useNavigate();
    const [items, setItems] = useState([{}]);

    useEffect(() => {
        const ItemsByCategory = async () => {
            try {
                const branchId = 1;
                const queryParams = new URLSearchParams({ branchId });
                const response = await fetch(`${URL}/api/public/v1/user/view/products/${categoryId}?${queryParams}`);
                console.log(response);
                console.log(response.data);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setItems(data);


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        ItemsByCategory();

    },[categoryId]);
    
    const handleVarietyClick = (item) => {

        console.log(item.itemId); 
     
    };



    const handleAddClick = (itemId) => {
        console.log("variety id: " + itemId);
        navigate(`/product/${itemId}`);
    };

  
  


    return (
        <>
        {/* <ToastContainer /> */}
        <div>
              
            {/* <Typography style={{ textAlign: 'left', marginLeft: '20px' }}>Looking for {selectedCategory.name}</Typography> */}
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', marginTop: '20px', marginRight: {sm:'50px',lg:5} }}>
            {items.map((variety, index) => (
                <Card key={index} style={{ width: '150px', margin: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }} onClick={() => handleVarietyClick(variety)}>
                    <CardMedia
                        component="img"
                        height="100"
                        image={`data:image/jpeg;base64,${variety.productImage}`}
                        alt={variety.name}
                    />
                    <CardContent>
                        <Typography variant="subtitle1" style={{ textAlign: 'center', marginBottom: '5px' }}>
                            {variety.name}
                        </Typography>
                        <Typography variant="subtitle1" style={{ textAlign: 'center', marginBottom: '10px' }}>
                            £ {variety.sellingPrice}
                        </Typography>
                        <Button
                            variant="outlined"
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#0094DC',
                                    color: '#fff',
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                },
                                '&:active': {
                                    backgroundColor: '#0094DC',
                                    color: '#fff',
                                    boxShadow: 'none',
                                },
                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                marginTop: 2
                            }}
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents the card click event
                                handleAddClick(variety.itemId);
                            }}
                        >
                            Add
                        </Button>
                    </CardContent>
                </Card>
            ))}
        </div>
            {/* {selectedVariety && <FoodDetail selectedVariety={selectedVariety} />} */}
        </div>
        </>
    );
}


export default SelectedCategory
