import React from 'react'
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import cardImage from '../assests/card.svg';
import netBankingImage from '../assests/net_banking.svg';
import upiImage from '../assests/upi.svg';

// import { Link } from 'react-router-dom';
const PaymentPage = () => {



  const [selectedValue, setSelectedValue] = React.useState('bankCards');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const boxStyle = {
    border: '0.4px solid #0094DC',
    width: { sm:'60%'},
    height: '100px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px', 
    borderRadius:20
  };
 
  return (
    <>
 
    <div>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Typography variant="h4" style={{ marginBottom: '20px', marginTop: '30px', textAlign: 'left', marginLeft: '30px', fontSize: '16px' }}>
        Payment Options
      </Typography>
      </div>
      <RadioGroup value={selectedValue} onChange={handleChange}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} lg={4}>
            <Box style={boxStyle}>
              <FormControlLabel
                value="bankCards"
                control={<Radio />}
                label="Bank Cards"
                style={{ marginRight: '20px' }}
              />
              <img src={cardImage} alt="Bank cards" style={{ width: '47px', height: '31px' }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={4}>
            <Box style={boxStyle}>
              <FormControlLabel
                value="netBanking"
                control={<Radio />}
                label="Net Banking"
                style={{ marginRight: '20px' }}
              />
              <img src={netBankingImage} alt="Net Banking" style={{ width: '47px', height: '31px' }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={4}>
            <Box style={boxStyle}>
              <FormControlLabel
                value="upi"
                control={<Radio />}
                label="UPI"
                style={{ marginRight: '20px' }}
              />
              <img src={upiImage} alt="UPI" style={{ width: '47px', height: '31px' }} />
            </Box>
          </Grid>
        </Grid>
      </RadioGroup>
     
    </div>
    </>
   
  )
}

export default PaymentPage
