import React, { useState, useEffect } from 'react';
import { useNavigate , useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import CircularProgress from '@mui/joy/CircularProgress';
import failureIcon from '../assests/failure.png';
import verfiedIcon from '../assests/verfied.png';
// import { URL } from '../constants/api';


const PaymentSuccessPage = () => {
    const navigate = useNavigate();
    const userId = localStorage.getItem("userid");

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');

    const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
    const [isloading, setIsLoading] = useState(true);


    useEffect(() => {
        const verifyPayment = async () => {
            if (!sessionId) {
                setIsPaymentSuccess(false);
                setIsLoading(false);
                return;
            }

            try {
                const response = await fetch(`https://beta.api.boss.m2r2.co.uk/api/v1/verify/payment/verify?sessionId=${sessionId}`,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    }

                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();

                if (data && data.isPaymentSuccess) {
                    setIsPaymentSuccess(true);
                    setIsLoading(false);
                    setTimeout(() => {
                        navigate(`/invoice/${data.invoiceId}`);
                    }, 5000);
                } else {
                    setIsPaymentSuccess(false);
                    setIsLoading(false);
                }
            } catch (error) {
                console.error("Error fetching payment data:", error);
                setIsLoading(false);
                setIsPaymentSuccess(false);
            }
        };

        verifyPayment();
    }, [sessionId, navigate, userId]);;
    

    


  return (
      <div className="background-white">
        <div className="background-white-sub-container"> 
        <div className='centralised-text'>

        {isloading ? (
        <div className='centralised-text'>
            <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
            <div className='centralised-text'><CircularProgress color="success" sx={{ '--CircularProgress-size': '80px' }}>
            <ReportIcon color="success" />
            </CircularProgress></div>
            <br/><br/>
            <div className='centralised-text extra-large-text'>Please wait while verifying your payment</div>
        </div>
        ) : (
        isPaymentSuccess ? (
            <div className='centralised-text'>
            <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
            <div className='centralised-text'>
            <img
                loading="lazy"
                alt="icon"
                srcSet={verfiedIcon}
                className="shrink-0 rounded-lg aspect-[1.02] w-[45px]"
                style={{ height: '80px', width: '80px' }}
              />
            </div>
            <br/><br/>
            <div className='centralised-text extra-large-text'>Payment verified, redirecting in 5 seconds</div>
            <br/><br/>If redirection fails, please click on the below button to perform a manual redirect
            <br/><br/><div className='centralised-text'><Button onClick={() => navigate("/orderDetails")} variant="soft">Continue</Button>
        </div></div>
            
        ) : (
            <div className='centralised-text'>
            <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
            <div className='centralised-text'>
            <img
                loading="lazy"
                alt="icon"
                srcSet={failureIcon}
                className="shrink-0 rounded-lg aspect-[1.02] w-[45px]"
                style={{ height: '80px', width: '80px' }}
              />
            </div>
            <br/><br/>
            <div className='centralised-text extra-large-text'>Payment verified failed, please retry payment</div>
            <br/><br/>If amount has been deducted from you, don't worry will be credited back to your account.
            <br/><br/><div className='centralised-text'><Button onClick={() => navigate("/checkout")} variant="soft">Continue</Button>
        </div></div>
                   )
        )}


    </div></div></div>
  )
}

export default PaymentSuccessPage
