import React  from 'react'

import HomeComponent from './Home';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
// import { CircularProgress } from '@mui/material';
const MainPage=()=>{




return(
    <>
    <HomeComponent/>
   
        
    
   
    

    </>
)

}

export default MainPage;