import React from 'react';
import { Box, Container, Typography, Link, Grid } from '@mui/material';

export default function HomeFooter() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Box component="footer" sx={{ mt: 'auto', backgroundColor: '#eeeeee', py: 3 }}>
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Help
              </Typography>
              <Link href="#" variant="subtitle1" color="textSecondary" style={{ textDecoration: 'none' }}>
                Shipping & Return Policy
              </Link>
              <br />
              <Link href="#" variant="subtitle1" color="textSecondary" style={{ textDecoration: 'none' }}>
                Terms & Conditions
              </Link>
              <br />
              <Link href="#" variant="subtitle1" color="textSecondary" style={{ textDecoration: 'none' }}>
                Privacy Policy
              </Link>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Shop
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Store Locator
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Typography variant="body2" align="center" color="textSecondary" sx={{ mt: 2 }}>
          © 2024 M2R2. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
}
