import React, { useState } from 'react';
import './style.css';
import { useNavigate } from "react-router-dom";
import Input from './Input';
import LoadingButton from '@mui/lab/LoadingButton';
import { FaUser, FaLock, FaFacebookF, FaGoogle, FaLinkedinIn } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PostRequest from '../services/PostRequest';


const RegistrationForm = () => {

    const navigate = useNavigate();
    const [serverErrorPopupOpen, setServerErrorPopupOpen] = useState(false);
    const [serverErrorContext, setServerErrorContext] = useState("Login failed");
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);

    const [title, setTitle] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [middlename, setMiddlename] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [error, setError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const [securityQuestion, setSecurityQuestion] = useState("");
    const [securityAnswer, setSecurityAnswer] = useState("");


    const handleServerErrorPopupClose = () => {
        setServerErrorPopupOpen(false);
      };

      const checkUsername = async (username) => {
        if (username) {
          try {
            const response = await fetch(`auth/validate/username?username=${username}`);
            if (response===true) {
              setUsernameError("Username is already taken");
              setError("Username is already taken");
            } else {
              setUsernameError("");
            }
          } catch (error) {
            setUsernameError("Error checking username");
          } finally {
            setTimeout(() => {
              setUsernameError("");
            }, 5000);
          }
        }
      }



    const handleRegister = () => {
        setUsernameError("");
        setEmailError("");
        setPasswordError("");
        setConfirmPasswordError("");
       

        // Perform validations
        let isValid = true;

        if (!email.trim()) {
            setEmailError("Email is required");
            isValid = false;
        }

        if (!username.trim()) {
            setUsernameError("Username is required");
            isValid = false;
        }

        if (!password.trim()) {
            setPasswordError("Password is required");
            isValid = false;
        }

        if (!confirmPassword.trim()) {
            setConfirmPasswordError("Confirm Password is required");
            isValid = false;
        } else if (password !== confirmPassword) {
            setPasswordError("Passwords do not match");
            setConfirmPasswordError("Passwords do not match");
            isValid = false;
        }

        if (isValid) {
            setSubmitted(true);
        }

        if (isValid) {
          setLoading(true); // Set loading to true before proceeding
          setTimeout(() => {
              setSubmitted(true);
              setLoading(false); // Set loading to false after processing
          }, 2000); // Simulating a delay for setup
      }
    };


    const completeSetup = async () => {
      // Construct JSON object with all required data

      let isValid = true;

        if (!firstname.trim()) {
            setError("First name is required");
            isValid = false;
        }

        if (!lastname.trim()) {
            setError("Lastname is required");
            isValid = false;
        }

        if (!securityAnswer.trim()) {
          setError("Security question answer is required");
          isValid = false;
      }

        if (!middlename.trim()) {
            setError("Middle name is required");
            isValid = false;
        }
        checkUsername(username);
       


      const data = {
          title: title,
          firstName: firstname,
          middleName: middlename,
          lastName: lastname,
          email: email, 
          userName: username,
          password: password,
          securityQuestions: [
              {
                  securityQuestion: securityQuestion,
                  securityQuestionAnswer: securityAnswer
              }
          ]
      };

  
      if (isValid) {
            setIsWaitingForResponse(true);
            // console.log("the package is",plan);
          await PostRequest('auth/register',data)
              .then(response => {
                  console.log("success")
                  if(response){
                    navigate("/verify-email", { state: { email } });
                    // navigate("/home");
                  } else{
                    setServerErrorContext("Registration failed");
                    setServerErrorPopupOpen(true);
                  }
              });
      }
  };

    const handleTitleChange = (e) => {
      setTitle(e.target.value);
  };

    const handleFirstNameChange = (e) => {
      setFirstname(e.target.value);
      setError("");
    };

    const handleMiddlenameChange = (e) => {
      setMiddlename(e.target.value);
      setError("");
    };

    const handleLastnameChange = (e) => {
      setLastname(e.target.value);
      setError("");
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        setUsernameError("");
    };

    const handlesecuirtyAnswerChange = (e) => {
      setSecurityAnswer(e.target.value);
      setError("");
  };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError("");
    };

    const handleSecurityQuestionChange = (e) => {
      setSecurityQuestion(e.target.value);
  };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError("");
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setConfirmPasswordError("");
    };

    return (
        <div className='login-form'>

          {loading ? (
            <>
                <div className="loading-bar">
                  <div class="loader"></div>
                  <br/>
                  <div className="loading-text">Please wait while loading...</div>
                </div></>
            ) :
            !submitted ? (
                <>
                    <div className="login-form-left-side-view">
                        <div className="login-content-wrapper">
                            <br /><br />
                            <div className="login-block-header">Welcome Back</div>
                            <br /><br />
                            To Keep connected, please perform login<br /><br /><br /><br /><br /><br /><br />
                            <button onClick={() => {navigate("/login");}} className='login-form-btn'>Login</button><br /><br />
                            
                            or
                            <div className ="nav-to-bome" onClick={() => {navigate("/");}}> Go back to Home</div><br /><br />
<br />
                        </div>
                    </div>
                    <div className="login-form-right-side-view">
                        <br /><br />
                        <div className="login-block-header">Create An account</div>
                        <br />
                        <div className="login-content-wrapper">
                            <Input
                                type="text"
                                value={email}
                                onChange={handleEmailChange}
                                placeholder="Email"
                                prefixIcon={<MdEmail />}
                            />
                            {emailError && <div className="error-message">{emailError}</div>}
                            <div className="input-padding"></div>
                            <Input
                                type="text"
                                value={username}
                                onChange={handleUsernameChange}
                                placeholder="Username"
                                prefixIcon={<FaUser />}
                            />
                            {usernameError && <div className="error-message">{usernameError}</div>}
                            <div className="input-padding"></div>
                            <Input
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                                placeholder="Password"
                                prefixIcon={<FaLock />}
                            />
                            {passwordError && <div className="error-message">{passwordError}</div>}
                            <div className="input-padding"></div>
                            <Input
                                type="password"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                placeholder="Confirm Password"
                                prefixIcon={<FaLock />}
                            />
                            {confirmPasswordError && <div className="error-message">{confirmPasswordError}</div>}
                            <div className="input-padding"></div>
                            <br />
                           
                            <button onClick={handleRegister} className='login-form-btn'>Create Account</button>
                            <br />
                            <div className="social-media-icons">
                                <div className="social-media-icon"><FaFacebookF /></div>
                                <div className="social-media-icon"><FaGoogle /></div>
                                <div className="social-media-icon"><FaLinkedinIn /></div>
                            </div>

                            <div className="mobile-view-footer">
                    <br/>
                    <div className="forgot-password-label">
                    <br /><br />
                    Already have an Account? Click here to <span className="text-span" onClick={() => {
                                                navigate("/login");
                                                            }}>Login</span></div>
                    
                    <br /><br />
                    <div className="forgot-password-label">
                        <br /><br />
                    Go back to <span className="text-span" onClick={() => {navigate("/");}}>Home</span>
                    </div>

            </div>

                        </div>
                    </div>
                </>
            ) : (
                <div className="registration-further-details">
                  <div className="login-content-wrapper">
                    <h3>You need to set up few more steps</h3>
                    <br/>
                    <div className="full-name-conatiner">
          
                          <select
                                value={title}
                                onChange={handleTitleChange}
                                className="selection-input-wrapper"
                            >
                                <option value="Mr">Mr</option>
                                <option value="Miss">Miss</option>
                                <option value="Mrs">Mrs</option>
                                {/* Add more options as needed */}
                            </select>
                            <div className="input-padding"></div>
                            <Input
                                type="text"
                                value={firstname}
                                onChange={handleFirstNameChange}
                                placeholder="Enter your First Name"
                            />
                          
                            <div className="input-padding"></div>
                            <Input
                                type="text"
                                value={middlename}
                                onChange={handleMiddlenameChange}
                                placeholder="Enter your Middle Name"
                            />
                            
                            <div className="input-padding"></div>
                            <Input
                                type="text"
                                value={lastname}
                                onChange={handleLastnameChange}
                                placeholder="Enter your Last Name"
                            />
                        
                            </div>
                            <div className="input-padding"></div>
                            <select
                                value={securityQuestion}
                                onChange={handleSecurityQuestionChange}
                                className="input-wrapper"
                            >
                                <option value="What is your mother's maiden name?">What is your mother's maiden name?</option>
                                <option value="What city were you born in?">What city were you born in?</option>
                                {/* Add more security questions as needed */}
                            </select>
                            <div className="input-padding"></div>
                            <Input
                                type="text"
                                value={securityAnswer}
                                onChange={handlesecuirtyAnswerChange}
                                placeholder="Security Answer"
                            />{error && <div className="error-message">{error}</div>}
                            <br />
                            <LoadingButton loading={isWaitingForResponse} variant="contained" onClick={completeSetup}>
                            Complete Setup
                             </LoadingButton>
                            <br />
                            
                        </div>
                        
            </div>
                
            )}
           <Snackbar open={serverErrorPopupOpen} autoHideDuration={6000} onClose={handleServerErrorPopupClose}>
                <Alert
                    onClose={handleServerErrorPopupClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {serverErrorContext}
                </Alert>
        </Snackbar>
        </div>
    );
};

export default RegistrationForm;
