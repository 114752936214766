import React , {useState ,useEffect} from 'react'
import MenuAppBar from '../components/Header';
import { Button } from '@mui/material';
// import burgerImage from "../assests/burger.svg";
import { Link } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { CheckCircleOutline } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { URL } from '../constants/api';
const ViewOrdersPage = () => {
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails]= useState([]);
  const handleReorderClick = () => {

    console.log('Reorder button clicked');
  };

  const handleRateClick = () => {
    navigate("/ratings")

  }


  useEffect(() => {
    const userId = localStorage.getItem('userid');
    console.log(userId)
    if (!userId) {
        toast.warn('You need to sign in to view your order details.', {
            onClose: () => navigate("/login"),
          });
        throw new Error("JWT token not found in localStorage");
    
    }
    const fetchData = async () => {
        try {
            const response = await fetch(`${URL}/api/public/v1/user/view/my-orders/${userId}`);
            // console.log(response);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data);
            setOrderDetails(data);
            // console.log(categories1)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
},[navigate]); 
  return (
    <>
      <MenuAppBar />
      <ToastContainer/>
      <Typography variant="h6" sx={{ textAlign: 'left', margin: 2 }}>Past Orders</Typography>
      <Card sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', marginTop: 2, marginLeft: { xs: 2, sm: 3, md: 4, lg: 4 }, marginRight: { xs: 2, sm: 3, md: 4, lg: 4 } }}>
        {/* <CardMedia
          component="img"
          sx={{ width: { xs: 60, md: 'auto' }, height: { xs: 50, md: 140, lg: 140 }, marginRight: { xs: 30 }, marginTop: { xs: 2 } }}
          image={burgerImage}
          alt="Food items"
        /> */}
        <CardContent sx={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        {orderDetails && orderDetails.map((orderDetail, index) => (
          
                 <div key={index} style={{ marginBottom: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', padding: '20px', borderRadius: '8px', background: '#fff' }}>
          <Grid container spacing={4}>
         
            <Grid item xs={4} sm={5} md={4} lg={4}>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
             
                {/* <Typography variant="body1">{orderDetails.orderNumber}</Typography> */}
                <Typography variant="body1" style={{ fontSize: '0.75rem' }}>Total Paid: £{orderDetail.unitPrice}</Typography>
               
                <Link to={`/order/${orderDetail.orderId}`}
                style={{ textDecoration: 'none', display: 'inline', color: "#0094DC", fontSize: '0.70rem', marginTop: 5, marginRight: 8 }}>
                  VIEW DETAILS
                </Link>
               
              
            
              </Box>
            </Grid>
           
            <Grid item xs={8} sm={7} md={8} lg={8} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
     
               
              <Typography variant="body2">ORDER #{orderDetail.orderNumber}</Typography>
              <Box display="flex">
                <Typography variant="body2" color="#0094DC" sx={{ fontSize: '0.65rem' }}>
                  Delivered on {orderDetail.orderDate}
                </Typography>
                <CheckCircleOutline color="success" sx={{ fontSize: '15px', marginLeft: 0.2 }} />
              </Box>
           
          
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', marginLeft: { md: 4, lg: 20 }, marginTop: 2}}>
              <Button
                variant="outlined"
                onClick={handleReorderClick}
                sx={{
                  width: '120px',
                  height: '36px',
                  color: '#0094DC',
                  ':hover': {
                    bgcolor: '#0094DC',
                    color: '#fff',
                  },
                  boxShadow: '0 3px 5px 2px rgba(0, 148, 220, .3)',
                }}
              >
                REORDER
              </Button>
              <Button
                variant="outlined"
                onClick={handleRateClick}
                sx={{
                  width: '120px',
                  height: '36px',
                  color: '#0094DC',
                  ':hover': {
                    bgcolor: '#0094DC',
                    color: '#fff',
                  },
                  boxShadow: '0 3px 5px 2px rgba(0, 148, 220, .3)',
                }}
              >
                RATE
              </Button>
            </Box>
          </Box>
       
      </div>
    
        ))}
        </CardContent>
      </Card>
    </>
  )
}

export default ViewOrdersPage;
