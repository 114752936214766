import React, { useState } from 'react';
import './style.css';
import Input from './Input';
import { FaUser, FaLock, FaFacebookF, FaGoogle, FaLinkedinIn } from 'react-icons/fa';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { parseJwt } from "../../services/JwtUtils"; // Import function to parse JWT token
import axios from 'axios';


const Login = ({onSuccess, onForgotPassword, onClickToRegister, onClickToHome}) => {
 
    const [serverErrorPopupOpen, setServerErrorPopupOpen] = useState(false);
    const [serverErrorContext, setServerErrorContext] = useState("Login failed");



    const [username,setUsername ] = useState("")
    const [password,setPassword ]=useState("")
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");


    const handleServerErrorPopupClose = () => {
        setServerErrorPopupOpen(false);
      };
    
    async function PostRequest(path = '', data, isAuthenticated) {
    let headers = {
        'Content-Type': 'application/json'
    };
    
    if (isAuthenticated) {
        const token = localStorage.getItem('token');
        headers['Authorization'] = `Bearer ${token}`;
    }
    
    try {
        const response = await axios.post(
        'https://beta.api.boss.m2r2.co.uk/api/' + path,
        data,
        { headers } // Pass headers as an object with 'headers' key
        );
    
        if (response.status === 200 || response.status === 201) {
        return response.data;
        } else if (response.status === 403) {
        setServerErrorContext("Login failed:", 'Username or Password is incorrect.');
        setServerErrorPopupOpen(true);
        return null;
        } else if (response.status === 401) {
        setServerErrorContext("Login failed:", 'Authentication failed. Please try to login first.');
        setServerErrorPopupOpen(true);
        return null;
        } else {
        setServerErrorContext("Login failed:", 'Server returned an error. Please try again later.');
        setServerErrorPopupOpen(true);
        return null;
        }
    } catch (error) {
        console.error('Error in postData:', error);
        setServerErrorContext("Login failed:", error);
        setServerErrorPopupOpen(true);
        return null;
    }
    }
   
    const handleLogin = async () => {
      setUsernameError("");
      setPasswordError("");
      // Perform validations
      let isValid = true;

      if (!username.trim()) {
          setUsernameError("Username is required");
          isValid = false;
      }

      if (!password.trim()) {
          setPasswordError("Password is required");
          isValid = false;
      }

      // Construct JSON object with username and password
      const data = {
          userName: username,
          password: password,
      };

      if (isValid) {
          try {
              const response = await PostRequest('auth/login', data, false);
              console.log(response);
              console.log(response.jwtToken);
              if (response === undefined) {
                  setServerErrorPopupOpen(true);
              } else {
                  localStorage.clear(); // Clear localStorage
                  localStorage.setItem("token", response.jwtToken);
                  localStorage.setItem("userid", response.userId);
                  localStorage.setItem("username", username);
                  localStorage.setItem("isLoggedIn", "true"); // Set as string
                  const jwtToken = localStorage.getItem("token");
                  if (jwtToken) {
                      const decodedToken = parseJwt(jwtToken); // Parse JWT token
                      const role = decodedToken.roles[0]; // Corrected property name to 'userRoles'
                      console.log("the user role is: " + role);
                      localStorage.setItem("userRole", role);
                      onSuccess();
                  }
              }
          } catch (error) {
              console.error("Login failed:", error);
              setServerErrorContext("Login failed:", error);
              setServerErrorPopupOpen(true);
          }
      }
  };

  

  
    const handleUsernameChange = (e) => {
      setUsername(e.target.value);
      setUsernameError("");
    };
  
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
      setPasswordError("");
    };
 
  

  return (
    <div className='login-form'>
      <div className="login-form-left-side-view">
      <div className="login-content-wrapper"><br/><br/>
      <div className="login-block-header">Create Account</div><br/><br/>
      New Here? Don’t worry Create Your Free Account today<br/><br/><br/><br/><br/>
      <button onClick={() => {onClickToRegister();}} className='login-form-btn'>Create An Account</button>
      <br /><br />or
                            <div className ="nav-to-bome" onClick={() => {onClickToHome();}}> Go back to Home</div><br /><br />
        
        </div>
      </div>
      <div className="login-form-right-side-view"><br/><br/>
        <div className="login-block-header">Welcome back</div>
<br/><br/>

<div className="login-content-wrapper">
                <Input
                    type="text"
                    value={username}
                    onChange={handleUsernameChange}
                    placeholder="Username"
                    prefixIcon={<FaUser />}
                />{usernameError && <div className="error-message">{usernameError}</div>}
            <div className="input-padding"></div>
                <Input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Password"
                    prefixIcon={<FaLock />}
                    suffixIcon
                /> {passwordError && <div className="error-message">{passwordError}</div>}
                 <div className="input-padding"></div>
            <div className="login-footer-text">
                <div className="forgot-password-label">
                Forgot Password? Click here to <span className="text-span" onClick={() => {
                                               onForgotPassword();
                                                          }}>reset Password</span>
                </div>
                
              </div><br/>
                <button onClick={handleLogin} className='login-form-btn'>Login</button>
                <br/>
                or<br/> Login with social media<br/><br/>
                <div className="social-media-icons">
                    <div className="social-media-icon"><FaFacebookF/></div>
                    <div className="social-media-icon"><FaGoogle/></div>
                    <div className="social-media-icon"><FaLinkedinIn/></div>
                </div>

                <div className="mobile-view-footer">
                    <br/>
                    <div className="forgot-password-label">
                    <br /><br />
                    New Here? Click here to <span className="text-span" onClick={() => {
                                                onClickToRegister();
                                                            }}>Register</span></div>
                    
                    <br /><br />
                    <div className="forgot-password-label">
                        <br /><br />
                    Go back to <span className="text-span" onClick={() => {onClickToHome();}}>Home</span>
                    </div>
                 </div>

                </div>
      </div>

            <Snackbar open={serverErrorPopupOpen} autoHideDuration={6000} onClose={handleServerErrorPopupClose}>
                <Alert
                    onClose={handleServerErrorPopupClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {serverErrorContext}
                </Alert>
        </Snackbar>
    </div>
  )
}

export default Login
