import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useCart } from '../context/CartContext';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { URL } from '../constants/api';
import MenuAppBar from '../components/Header';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';


const FoodDetail = () => {

    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1);
    // const { addToCart } = useCart();
    const [itemDetails, setItemDetails] = useState([]);
    const { itemId } = useParams();
    // const userId = localStorage.getItem("userid");
    const { addItemToCart } = useCart();
    useEffect(() => {
        const fetchData = async () => {
            if (!itemId) {
                console.error('itemId is undefined');
                return;
            }
            try {
                const response = await fetch(`${URL}/api/public/v1/user/view/product/${itemId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setItemDetails(Array.isArray(data) ? data : [data]);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [itemId]);

    const handleQuantityChange = (amount) => {
        if (quantity + amount >= 1) {
            setQuantity(quantity + amount);
        }
    };

    // const addItemToCart = async () => {
    //     try {
    //         console.log('Adding item to cart...');
    //         console.log("userId " + userId);
    //         const jwtToken = localStorage.getItem('token');
    //         if (!jwtToken) {
    //             toast.warn('You need to sign in to add items to the cart.', {
    //                 onClose: () => navigate("/login"),
    //             });
    //             throw new Error("JWT token not found in localStorage");

    //         }
    //         const response = await fetch(`${URL}/api/public/v1/user/cart/addItem`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${localStorage.getItem('token')}`
    //             },
    //             body: JSON.stringify({ itemId: itemId, quantity }),
    //         });
    //         if (!response.ok) {
    //             throw new Error('Failed to add item to cart');
    //         }
    //         console.log('Item added to cart successfully');
    //         // addToCart({ ...itemDetails[0], quantity });
    //         // navigate('/home');
    //     } catch (error) {
    //         console.error('Error adding item to cart:', error);

    //     }
    // };
    const handleAddToCart = async () => {
        const jwtToken = localStorage.getItem('token');
        if (!jwtToken) {
            toast.warn('You need to sign in to add items to the cart.', {
                onClose: () => navigate("/login"),
            });
            // throw new Error("JWT token not found in localStorage");

        }
        addItemToCart(itemId, quantity);
    };

    const handleBuyNow = async () => {
        const jwtToken = localStorage.getItem('token');
        if (!jwtToken) {
            toast.warn('You need to sign in to add items to the cart.', {
                onClose: () => navigate("/login"),
            });
            // throw new Error("JWT token not found in localStorage");

        }
        addItemToCart(itemId, quantity);
        if (jwtToken) {
            navigate("/checkout");
        }

    }
    return (
        <>
            <MenuAppBar />
            <ToastContainer />
            <div style={{ paddingTop: '40px' }}>
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        {itemDetails && itemDetails.map((variety, index) => (
                            <Card key={index} style={{ marginBottom: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
                                <CardMedia
                                    component="img"
                                    alt={variety.name}
                                    height="200"
                                    image={`data:image/jpeg;base64,${variety.productImage}`}
                                    title={variety.name}
                                />
                                <CardContent style={{ padding: '20px', background: '#fff' }}>
                                    <Typography variant="h5" gutterBottom>{variety.name}</Typography>
                                    <Typography variant="body1" gutterBottom>{variety.description}</Typography>
                                    <Typography variant="body1">Price: £ {variety.sellingPrice}</Typography>
                                </CardContent>
                            </Card>
                        ))}


                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item>
                                <Typography variant="subtitle1">Quantity:</Typography>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button variant="outlined" onClick={() => handleQuantityChange(-1)}>-</Button>
                                    <Typography variant="body1" style={{ margin: '0 10px', width: '50px' }}>{quantity}</Typography>
                                    <Button variant="outlined" onClick={() => handleQuantityChange(1)}>+</Button>
                                </div>
                            </Grid>
                        </Grid>

                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Button variant="outlined" sx={{
                                width: '120px',
                                marginTop: 2,
                                marginLeft: { xs: 18, sm: 40, md: 30, lg: 50 },

                                '&:hover': {
                                    backgroundColor: '#0094DC',
                                    color: '#fff',
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                },
                                '&:active': {
                                    backgroundColor: '#0094DC',
                                    color: '#fff',
                                    boxShadow: 'none',
                                },
                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
                            }}
                                onClick={handleBuyNow}
                            >
                                Buy Now</Button>
                            <Button variant="outlined" sx={{
                                width: '120px',
                                marginTop: 5, marginLeft: { xs: 18, sm: 40, md: 30, lg: 50 },
                                color: "#0094DC", '&:hover': {
                                    backgroundColor: '#0094DC',
                                    color: '#fff',
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                },
                                '&:active': {
                                    backgroundColor: '#0094DC',
                                    color: '#fff',
                                    boxShadow: 'none',
                                },
                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
                            }} onClick={handleAddToCart}>Add Item</Button>
                        </div>

                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default FoodDetail;
