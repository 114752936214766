import React from 'react'
import MenuAppBar from '../components/Header'
import Typography from '@mui/material/Typography';
import cancelImage from '../assests/cancel.svg';

const CancelOrderPage = () => {
  return (
    <div>
      <MenuAppBar/>
      <img src={cancelImage} alt="cancel" style={{ width: '200px', marginTop: '20px' }} />
      <Typography variant="body2" sx={{ mt: 2, mb: 1, textAlign: 'center', paddingLeft: '30px' }}>
       Your order has been cancelled
      </Typography>
    </div>
  )
}

export default CancelOrderPage
