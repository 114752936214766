import './App.css';
import HomeComponent from './pages/Home';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CartComponent from './pages/Cart';
import FoodDetail from './pages/FoodDetail';
import PaymentPage from './pages/Payment';
import OrderConfirmationPage from './pages/OrderConfirmationPage';
import OrderTrackingPage from './pages/OrderTrackingPage';
import CancelOrderPage from './pages/CancelOrderPage';
import { CartProvider } from '../src/context/CartContext';
import DeliveryOptions from './pages/DeliveryOptions';
import SelectedCategory from './pages/SelectedCategory';
import RegistrationForm from './components/Registration';
import ViewOrderPage from './pages/ViewOrdersPage';
import RatingForProduct from './pages/RatingForProduct';
import OrderDetailsPage from './pages/OrderDetailsPage';
import ReturnOrReplace from './pages/ReturnOrReplace';
import ReviewProduct from './pages/ReviewProduct';
import Checkout from './pages/Checkout';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import PaymentFailurePage from './pages/PaymentFailurePage';
import LoginPage from './pages/Login';
import MainPage from './pages/MainPage';
import VerifyEmailPage from './pages/VerifyEmail';
import InvoicePage from './pages/InvoicePage';
function App() {
  return (
    <>
    <div className="App">
    <CartProvider>
    <BrowserRouter>
      <Routes>
     
        <Route path = "/" element={<MainPage/>}/>
        <Route path = "/home" element={<HomeComponent/>}/>
        <Route path = "/cart" element={<CartComponent />} />
        <Route path = "/product/:itemId" element={<FoodDetail />} />
        <Route path = "/payment" element={<PaymentPage/>}/>
        <Route path = "/order-confirmation" element={<OrderConfirmationPage/>}/>
        <Route path = "/order-tracking" element={<OrderTrackingPage/>}/>
        <Route path = "/cancel" element={<CancelOrderPage/>}/>
        <Route path = "/delivery-options" element={<DeliveryOptions/>}/>
        <Route path = "/selected-category" element={<SelectedCategory/>}/>
        <Route path = "/login" element={<LoginPage/>}/>
        <Route path = "/register" element={<RegistrationForm/>}/>
        <Route path = "/orders" element={<ViewOrderPage/>}/>
        <Route path = "/order/:orderId" element={<OrderDetailsPage/>}/>
        <Route path = "/ratings" element= {<RatingForProduct/>}/>
        <Route path = "/returnOrReplace" element={<ReturnOrReplace/>}/>
        <Route path = "/productReview" element={<ReviewProduct/>}/>
        <Route path = "/checkout" element={<Checkout/>}/>
        <Route path = "/payment/success*" element ={<PaymentSuccessPage/>}/>
        <Route path = "/payment/failure" element ={<PaymentFailurePage/>}/>
        <Route path =  "/verify-email" element ={<VerifyEmailPage/>}/>
        <Route path = "/invoice/:invoiceId" element ={<InvoicePage/>}/>
     
  
     
      </Routes>
    
      </BrowserRouter>
      </CartProvider>
    
    </div>
    </>
  );
}

export default App;
